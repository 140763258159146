<template>
  <div
    :class="{
      'osk-collapse-item': true,
      'osk-collapse-item_wide': isWide,
      'osk-collapse-item_small': small,
    }"
  >
    <div
      v-if="title || slots.title"
      class="osk-collapse-item__title"
      @click="handleLabelClick"
    >
      <UikitIcon
        v-if="icon"
        :name="icon"
        :size="small ? 'm' : 'l'"
        class="osk-collapse-item__title-icon"
      />

      <div
        class="osk-collapse-item__title-text"
      >
        <slot name="title">
          {{ title }}
        </slot>
      </div>

      <UikitIcon
        v-if="(!$collapse || !$collapse.disable?.value) && !isWide"
        name="ChevronDown"
        size="m"
        color="grey"
        :class="{
          'osk-collapse-item__title-arrow': true,
          'osk-collapse-item__title-arrow_active': isActive,
        }"
      />

      <div
        v-if="isWide"
        :class="{
          'osk-collapse-item__plus': true,
          'osk-collapse-item__plus_active': isActive,
        }"
      >
        &nbsp;
      </div>
    </div>

    <UikitTransitionCollapse
      class="osk-collapse-item__content"
      :show="isActive"
    >
      <slot />
    </UikitTransitionCollapse>
  </div>
</template>

<script lang="ts" setup>
import type { TIconNames } from '~/assets/icons';

import { collapseKey } from '~/types/components/collapse';

const props = defineProps<{
  title?: string
  name?: string
  icon?: TIconNames
  small?: boolean
}>();

const $collapse = inject(collapseKey);

const slots = useSlots();

const onlyItemActive = ref(false);

const nameComp = computed(() => props.name ?? String(Math.floor(Math.random() * 10000)));
const isWide = computed(() => {
  if (!$collapse) return false;

  return $collapse.wide;
});
const isActive = computed(() => {
  if (!$collapse) return onlyItemActive.value;
  if ($collapse.disable?.value) return true;

  return $collapse?.activeNames.value.includes(nameComp.value);
});

function handleLabelClick() {
  if (!$collapse) {
    onlyItemActive.value = !onlyItemActive.value;
    return;
  }

  if ($collapse.disable?.value) return;

  $collapse.handleItemClick(nameComp.value);
}
</script>

<style scoped lang="scss">
@import "~/assets/scss/settings/index";

.osk-collapse-item {
  &__title {
    display: flex;
    align-items: center;
    font-size: $font-size-subheading;
    line-height: $line-height-subheading-2;
    cursor: pointer;
    padding: $indent-compact 0;
  }

  &__title-icon {
    margin-right: $indent-medium;
  }

  &__title-arrow {
    margin-left: auto;
    transition: transform linear 0.3s;

    &_active {
      transform: rotate(180deg)
    }
  }

  &_wide {
    border-top: 1px solid $grey-3;

    &:last-child {
      border-bottom: 1px solid $grey-3;
    }

    & > .osk-collapse-item__title {
      @include font-style($font-size-heading, $line-height-heading, $font-weight-bold);
      padding: $indent-mmedium 0;
    }
  }

  &__plus {
    position: relative;
    width: 18px;
    height: 18px;
    margin-left: auto;
    padding: 2px;
    transform: translateX(-1px);

    &:after {
      content: '';
      width: 14px;
      height: 2px;
      background: #000;
      border-radius: 100px;
      overflow: hidden;
      position: absolute;
      top: 50%;
      left: 2px;
      transform: translateY(-50%);
    }

    &:before {
      content: '';
      width: 2px;
      height: 14px;
      background: #000;
      border-radius: 100px;
      overflow: hidden;
      position: absolute;
      left: 50%;
      top: 2px;
      opacity: 1;
      transform: translateX(-50%);
      transition: all linear .2s;
    }

    &_active {
      &:before {
        transform: translateX(-50%) rotate(90deg);
        opacity: 0;
      }
    }
  }

  &_small {
    .osk-collapse-item__title {
      padding: 0;
    }

    .osk-collapse-item__title-text {
      font-size: 14px;
      font-weight: 500;
      line-height: normal;
    }

    .osk-collapse-item__title-icon {
      margin-right: 4px;
    }
  }
}
</style>
