<template>
  <div class="osk-collapse">
    <slot />
  </div>
</template>

<script lang="ts" setup>
import { isArrayEquals } from '~/utils/extra';
import { collapseKey, TName, TModel } from '~/types/components/collapse';

const props = withDefaults(defineProps<{
  modelValue?: TModel
  accordion?: boolean
  disable?: boolean
  wide?: boolean
}>(), {
  modelValue: () => ([] as TName[]),
});

const emits = defineEmits<{
  (e: 'update:modelValue', value: TModel): void
  (e: 'itemClick', value: TName, isActive: boolean): void
}>();

const activeNames = ref(getNames());
const disableComp = computed(() => !!props.disable);

function handleItemClick(name: TName) {
  const index = activeNames.value.indexOf(name);

  if (props.accordion) {
    const value = index > -1 ? '' : name;

    activeNames.value[0] = value;
    emits('itemClick', name, activeNames.value.includes(name));
    emits('update:modelValue', value);
    return;
  }

  if (index > -1) {
    activeNames.value.splice(index, 1);
  } else {
    activeNames.value.push(name);
  }

  emits('itemClick', name, activeNames.value.includes(name));
  emits('update:modelValue', activeNames.value);
}

function getNames() {
  const { modelValue } = props;

  return Array.isArray(modelValue) ? [...modelValue] : [modelValue];
}

watch(() => props.modelValue, (value) => {
  if (props.accordion && activeNames.value[0] !== value) {
    activeNames.value = getNames();

    return;
  }

  if (Array.isArray(value) && !isArrayEquals(activeNames.value, value)) {
    activeNames.value = getNames();
  }
}, { deep: true });

provide(collapseKey, {
  activeNames,
  handleItemClick,
  disable: disableComp,
  wide: props.wide,
});
</script>
