import { InjectionKey, Ref, ComputedRef } from 'vue';

export type TName = string;
export type TModel = TName | TName[];

interface CollapseContext {
  activeNames: Ref<TName[]>
  handleItemClick: (name: string) => void
  disable?: ComputedRef<boolean>
  wide?: boolean
}

export const collapseKey: InjectionKey<CollapseContext> = Symbol('collapseContextKey');
